import React from 'react'

import { Image } from '@te-digi/styleguide'

import homeImageSectionJpg from '@te-digi/styleguide/assets/home-image-section.jpg'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Image"
    components={[{ component: Image }]}
  >
    <Section>
      <Playground>
        <Image
          alt="Lorem ipsum dolor sit amet, consectetuer adipiscing elit"
          fluid
          noMargin
          src={homeImageSectionJpg}
        />
      </Playground>
      <Playground format="html">
        <img
          alt="Lorem ipsum dolor sit amet, consectetuer adipiscing elit"
          className="img img-fluid mb-0"
          src={homeImageSectionJpg}
        />
      </Playground>
    </Section>
  </Content>
)

export default Page
